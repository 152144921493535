import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Matrix from "../components/matrix";
import Layout from "../components/layout";
import SectionHead from "../components/newComponents/sectionHead";
import ImgixFluidImage from "../components/newComponents/ImgixFluidImage";
import PlaceHolderImage from "../components/newComponents/placeHolderImage";
import EditLink from "../components/newComponents/editLink";

export const query = graphql`
  query StaffEntryQuery($slug: String!) {
    entry: craftStaffStaffEntry(slug: { eq: $slug }) {
      id
      remoteId
      title
      position
      email
      linkedinProfileUrl
      sectionId
      sectionHandle
      remoteId
      slug
      featureImage {
        ...AssetFragment
      }
      bodyContent {
        ...BodyContentFragment
      }
    }
  }
`;

const NewsEntry = ({ data: { entry },pageContext }) => {

  const image = entry.featureImage[0]
  const finalImage = image ? <ImgixFluidImage attribution={image.attribution} imageUrl={image.url} sizes="600px" ar={image.width / image.height} focalPoint={image.focalPoint} />  : <PlaceHolderImage />;

  return (
    <Layout pageContext={pageContext}>
      <Seo title={entry.title} />      
      <div className="main">
        <section className="section-inside">
          <div className="shell">
            <div className="grid sm:grid-cols-12 gap-4">
              <div className="sm:col-span-8"> 
                <SectionHead heading={entry.title} date={entry.position} email={entry.email} linkedin={entry.linkedinProfileUrl}/>

                <Matrix blocks={entry.bodyContent} />
              </div>
              <div className="sm:col-span-4">{finalImage}</div>
              <EditLink remoteId={entry.remoteId} sectionHandle={entry.sectionHandle} />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default NewsEntry;